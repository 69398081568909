section.section-main {
    min-height: 100vh;
    background-color: $gray;

    padding-top: 100px;
    @include lg {
        padding-top: 138px;
    }

    .background-image {
        top: 100px;
        @include lg {
            top: 138px;
        }

        position: absolute;
        left: 0px;
        height: 564px;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .background-image-layer1 {
        top: 100px;
        @include lg {
            top: 138px;
        }

        position: absolute;
        left: 0px;
        height: 564px;
        width: 100%;
        background: linear-gradient(96deg, #000000 5.99%, rgba(0, 0, 0, 0) 98.82%);
        opacity: 0.73;
    }
    .background-image-layer2 {
        top: 100px;
        @include lg {
            top: 138px;
        }

        position: absolute;
        left: 0px;
        height: 564px;
        width: 100%;
        background: linear-gradient(90.97deg, #7c8f36 0.45%, #cd2b38 29.7%, #58459f 65.11%, #ea771c 98.98%);
        opacity: 0.23;
    }

    .intro-container {
        position: absolute;
        top: 138px;
        left: 0px;
        width: 100%;
        // height: 564px;
        overflow: hidden;

        padding-top: 60px;
        padding-left: 24px;
        @include md {
            padding-top: 114px;
        }
        @include lg {
            padding-left: 0px;
            padding-top: 145px;
        }

        em {
            font-style: italic;
        }

        .intro-title {
            font-weight: 800;
            color: $white;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

            font-size: 31px;
            line-height: 51px;
            text-align: center;

            @include md {
                font-size: 40px;
            }

            @include lg {
                font-size: 48px;
                line-height: 58px;
                text-align: left;
            }
        }

        .intro-paragraph-container {
            font-weight: 400;
            line-height: 33px;
            color: $white;

            font-size: 16px;
            text-align: center;

            @include md {
                font-size: 18px;
            }

            @include lg {
                font-size: 24px;
                text-align: left;
            }
        }

        .intro-paragraph-text {
            //max-width: 670px;
            max-width: 630px;
        }

        .intro-button-container {
            text-align: center;
            @include lg {
                text-align: left;
            }
            @include xl {
                margin-top: 96px;
            }
        }
    }

    .promo-video-container-desktop {
        display: none;

        @include lg {
            display: initial;
        }

        .promo-video-properties-desktop {
            height: 300px;
            width: '100%';
            border-radius: 5px;
            object-fit: contain;
        }
    }
    .promo-video-container-mobile {
        display: block;

        @include lg {
            display: none;
        }

        .promo-video-properties-mobile {
            border-radius: 5px;
            object-fit: contain;
            height: 300;
            width: 100%;
            margin-top: 60px;
        }
    }

    .features-container {
        background-color: $gray_light;
        color: $white;
        margin-top: 564px;

        padding-top: 57px;
        padding-bottom: 57px;
        text-align: center;

        .features-title {
            font-weight: 800;
            font-size: 22px;
            line-height: 26px;
            position: relative;

            .underlined {
                height: 2px;
                width: 100%;

                position: absolute;
                bottom: -13px;
                left: 0px;
            }
        }

        .features-paragraph {
            margin-top: 40px;
            font-size: 18px;
            line-height: 33px;
            margin-bottom: 20px;

            @include lg {
                margin: 0 auto;
                text-align: center;
                width: 50%;
                padding-top: 40px;
            }
        }

        .features-items-container {
            margin-top: 60px;

            display: flex;
            flex-wrap: wrap;

            .feature-item {
                margin-bottom: 20px;

                display: flex;
                text-align: left;
            }

            .feature-icon {
                height: 28px;
                width: 28px;

                margin-right: 26px;
            }
        }
    }

    .favorite-items-container {
        background-color: $gray;
        padding-top: 37px;

        .sample-title-container {
            padding-top: 20px;
            padding-bottom: 20px;

            text-align: center;
            @include lg {
                text-align: left;
            }

            .sample-title {
                font-weight: 800;
                font-size: 22px;
                line-height: 26px;
                color: $white;
                position: relative;

                .underlined {
                    height: 2px;
                    width: 100%;

                    position: absolute;
                    bottom: -20px;
                    left: 0px;
                }

                .highlighted {
                    color: $orange;
                }
            }
        }

        .sample-items {
            margin-top: 45px;
            margin-bottom: 28px;
            color: $white;

            justify-content: center;
            @include md {
                justify-content: space-between;
                max-height: 570px;
                overflow: hidden;
            }

            .sample-item {
                width: auto;
                margin-bottom: 40px;
                margin-left: 10px;
                margin-right: 10px;

                @include md {
                    margin-left: 0px;
                    margin-right: 0px;
                    margin-bottom: 0px;
                }
            }
        }

        .goto-catalog {
            padding-bottom: 114px;

            text-align: center;
            @include md {
                text-align: right;
            }
            @include lg {
                padding-top: 40px;
            }

            .link-text {
                cursor: pointer;

                font-weight: 800;
                font-size: 17px;
                line-height: 20px;
                text-align: right;
                color: $white;
                width: 100%;

                &:hover {
                    color: $orange;
                }
            }
        }
    }
}
