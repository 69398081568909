section.section-404 {
    min-height: 100vh;
    background-color: $gray;
    color: $white;
    text-align: center;

    padding-top: 40vh;
    // padding-top: 100px;
    // @include lg {
    //     padding-top: 138px;
    // }

    .first-line {
        font-weight: 400;
        color: $menu_gray1;
        line-height: normal;
    }

    .second-line {
        font-weight: 800;
        font-size: 85px;
        line-height: normal;
        position: relative;

        .underlined {
            height: 2px;
            width: 100%;
            position: absolute;
            bottom: 0px;
            left: 0px;
        }
    }

    .third-line {
        margin-top: 10px;
        font-size: 21px;
    }

    .fourth-line {
        margin-top: 40px;
        font-size: 16px;
    }
}
