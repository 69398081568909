section.section-catalog-item {
    .catalog-background {
        background-color: $gray_light;
        border-radius: 5px;
        padding-bottom: 17px;
    }

    .catalog-item {
        position: relative;
        margin-left: 5%;
        width: 90%;
        @include md {
            margin-left: 0px;
            width: 214px;
        }
        @include lg {
            width: 271px;
        }

        .item-picture-background {
            height: 296px;
            @include md {
                width: 214px;
            }
            @include lg {
                width: 271px;
            }
        }

        .item-picture {
            background-color: $preview_background;
            height: 296px;
            width: 100%;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;

            .-cp {
                cursor: pointer;
            }

            position: relative;

            .image {
                height: 100%;
                width: 100%;
                user-select: none;

                &.-contain {
                    object-fit: contain;
                }
            }

            .watermark {
                position: absolute;
                height: 100%;
                width: 100%;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                user-select: none;
                object-fit: cover;
            }
        }

        .item-title {
            padding-top: 17px;
            padding-left: 17px;
            padding-right: 17px;

            font-weight: 700;
            font-size: 18px;
            line-height: 33px;

            height: 50px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .item-description {
            padding-left: 17px;
            padding-right: 17px;
            padding-top: 17px;

            height: 90px;
            width: auto;
            overflow: hidden;

            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: $white;
        }
        .tags {
            padding-top: 26px;
            padding-left: 17px;
            padding-right: 17px;
            display: flex;
        }
        .item-tag {
            background-color: $button_gray;
            border-radius: 5px;
            height: 36px;
            padding-left: 15px;
            padding-right: 15px;
            padding-top: 1px;

            max-width: 50%;
            margin-right: 10px;

            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            text-align: center;
            font-weight: 700;
            font-size: 12px;
            line-height: 33px;
        }
    }

    .custom-view-number {
        position: absolute;
        top: -15px;
        right: -15px;
        padding: 10px 20px;
        z-index: 997;
        border-radius: 6px;
    }
}
