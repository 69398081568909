.dark-background {
    position: fixed;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    pointer-events: none;

    background: rgba(0, 0, 0, 0);
    transition: 0.3s;
    z-index: 9998;

    &.open {
        background: rgba(0, 0, 0, 0.8);
        transition: 0.3s;
        pointer-events: all;
    }
}

.modal-login-register {
    display: none;
    background-color: $mobile_menu;
    z-index: 9999;
    pointer-events: all;

    position: fixed;
    overflow-y: auto;
    overflow-x: hidden;
    overscroll-behavior: contain;

    top: 1vh;
    bottom: 1vh;
    left: 10vw;
    right: 10vw;
    width: 80vw;
    height: 98vh;

    @include lg {
        top: 5vh;
        bottom: 5vh;
        left: 10vw;
        right: 10vw;
        width: 80vw;
        height: 90vh;
    }

    @include xl {
        left: 20vw;
        right: 20vw;
        width: 60vw;
    }

    color: $white;
    padding-bottom: 100px;

    .error-text {
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: $error_text;
        margin-bottom: 15px;
    }

    &.open {
        display: block;
    }

    .divider {
        width: 100%;
        height: 3px;
        background-color: $gray_light;
    }

    .icon-close-container {
        cursor: pointer;
        position: absolute;
        top: 26px;
        right: 26px;

        height: 40px;
        width: 40px;
        padding-top: 6px;
        padding-left: 13px;

        .icon-close {
            width: 14px;
            height: 14px;
        }
    }

    .title {
        margin-top: 52px;
        font-weight: 800;
        font-size: 25px;
        line-height: 30px;
    }

    .field-error {
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: $error_text;
        min-height: 40px;
        padding-top: 11px;
        padding-bottom: 11px;
    }

    input {
        //background-color: $gray_light;
        background-color: transparent !important;
        color: $white !important;
        box-shadow: none;
        border: 0px;
        width: 100%;
        margin-top: 12px;
        padding-left: 10px;
    }
    input:focus,
    textarea:focus,
    select:focus {
        outline: none;
    }
    input:-webkit-autofill {
        -webkit-text-fill-color: $white !important;
        background-color: transparent !important;
        -webkit-box-shadow: 0 0 0 50px $gray_light inset;
    }

    .login-block {
        padding-left: 60px;
        padding-right: 60px;

        border-right: 2px;
        border-style: solid;
        border-color: $gray_light;

        .input-email-text {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 25px;
        }
        .input-email-field {
            background-color: $gray_light;
            border-radius: 7px;
            height: 51px;
            margin-bottom: 15px;
            padding-left: 15px;
            padding-right: 15px;

            &.error {
                border-color: $error_border;
                border-width: 1px;
                border-style: solid;
            }
        }

        .input-password-text {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            padding-top: 15px;
            margin-bottom: 25px;
        }
        .input-password-field {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            padding-top: 2px;
            padding-left: 15px;

            background-color: $gray_light;
            border-radius: 7px;
            height: 51px;
            margin-bottom: 15px;
            padding-right: 45px;
            position: relative;

            &.error {
                border-color: $error_border;
                border-width: 1px;
                border-style: solid;
            }

            .icon-password {
                width: 21px;
                height: auto;
                position: absolute;
                right: 20px;
                cursor: pointer;

                top: 17px;
                &.visible {
                    top: 19px;
                }
            }
        }
        .input-password-text-forgot {
            font-style: italic;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            text-align: right;

            position: absolute;
            top: 64px;
            right: 0px;

            cursor: pointer;
            &:hover {
                color: $orange;
            }
        }

        .login-button {
            font-weight: 800;
            font-size: 16px;
            line-height: 19px;
            text-align: center;

            margin-top: 65px;
            &.error {
                margin-top: 35px;
            }

            background-color: $red;
            border-radius: 9px;

            padding-top: 15px;
            height: 51px;

            cursor: pointer;
            &:hover {
                background-color: $purple;
            }

            .loading-icon {
                height: auto;
                width: 24px;
                margin-top: -2px;

                -webkit-user-select: none;
                -ms-user-select: none;
                user-select: none;
            }
        }

        .reset-email-text {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 25px;
        }
        .reset-email-field {
            background-color: $gray_light;
            border-radius: 7px;
            height: 51px;
            margin-bottom: 15px;
            padding-left: 15px;
            padding-right: 15px;

            &.error {
                border-color: $error_border;
                border-width: 1px;
                border-style: solid;
            }
        }
        .reset-button {
            font-weight: 800;
            font-size: 16px;
            line-height: 19px;
            text-align: center;

            background-color: $red;
            border-radius: 9px;

            height: 51px;
            padding-top: 15px;

            margin-top: 35px;

            cursor: pointer;
            &:hover {
                background-color: $purple;
            }

            .loading-icon {
                height: auto;
                width: 24px;
                margin-top: -2px;

                -webkit-user-select: none;
                -ms-user-select: none;
                user-select: none;
            }
        }
    }

    .register-block {
        padding-left: 60px;
        padding-right: 60px;

        .namecol {
            width: 48%;
        }
        .name-error-block {
            margin-top: 15px;

            .error-text {
                bottom: 10px;
            }
        }
        .register-firstname-text {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 25px;
        }
        .register-firstname-field {
            background-color: $gray_light;
            border-radius: 7px;
            height: 51px;
            padding-left: 15px;
            position: relative;

            &.error {
                border-color: $error_border;
                border-width: 1px;
                border-style: solid;
            }
        }
        .register-lastname-text {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 25px;
        }
        .register-lastname-field {
            background-color: $gray_light;
            border-radius: 7px;
            height: 51px;
            padding-left: 15px;
            position: relative;

            &.error {
                border-color: $error_border;
                border-width: 1px;
                border-style: solid;
            }
        }

        .register-email-text {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            padding-top: 15px;
            margin-bottom: 25px;
        }
        .register-email-field {
            background-color: $gray_light;
            border-radius: 7px;
            height: 51px;
            margin-bottom: 15px;
            padding-left: 15px;
            padding-right: 15px;
            position: relative;

            &.error {
                border-color: $error_border;
                border-width: 1px;
                border-style: solid;
            }
        }

        .register-password-text {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            padding-top: 15px;
            margin-bottom: 25px;
        }
        .register-password-field {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            padding-top: 2px;
            padding-left: 15px;
            position: relative;

            background-color: $gray_light;
            border-radius: 7px;
            height: 51px;
            margin-bottom: 15px;
            padding-right: 45px;
            position: relative;

            &.error {
                border-color: $error_border;
                border-width: 1px;
                border-style: solid;
            }

            .icon-password {
                width: 21px;
                height: auto;
                position: absolute;
                right: 20px;
                cursor: pointer;

                top: 17px;
                &.visible {
                    top: 19px;
                }
            }
        }

        .register-disclaimer {
            font-weight: 500;
            font-size: 12px;
            line-height: 25px;
            padding-top: 15px;

            .register-disclaimer-link {
                text-transform: lowercase;
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .register-button {
            font-weight: 800;
            font-size: 16px;
            line-height: 19px;
            text-align: center;

            margin-top: 30px;
            padding-top: 15px;
            height: 51px;

            background-color: $red;
            border-radius: 9px;

            cursor: pointer;
            &:hover {
                background-color: $purple;
            }

            .loading-icon {
                height: auto;
                width: 24px;
                margin-top: -2px;

                -webkit-user-select: none;
                -ms-user-select: none;
                user-select: none;
            }
        }

        .reg-success-title {
            font-weight: 500;
            font-size: 16px;
            line-height: 33px;

            margin-bottom: 20px;
        }

        .reg-success-text {
            font-weight: 500;
            font-size: 16px;
            line-height: 33px;
        }
    }
}
