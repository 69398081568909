.dark-background {
    position: fixed;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    pointer-events: none;

    background: rgba(0, 0, 0, 0);
    transition: 0.3s;
    z-index: 998;

    &.open {
        background: rgba(0, 0, 0, 0.8);
        transition: 0.3s;
        pointer-events: all;
    }
}

.modal-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 9999;
}

.modal-alert {
    display: none;
    background-color: $mobile_menu;
    z-index: 999;
    pointer-events: all;

    overflow-y: auto;
    overflow-x: hidden;
    overscroll-behavior: contain;

    position: fixed;
    border-radius: 7px;
    padding: 10px;

    width: fit-content;
    max-width: 600px;

    color: $white;

    &.open {
        display: block;
    }

    .close-container {
        padding: 15px;
        position: absolute;
        top: 0px;
        right: 5px;

        .icon-close {
            width: 14px;
            height: 14px;
        }

        &:hover {
            cursor: pointer;
            opacity: 0.7;
        }
    }

    .inner-block {
        padding-top: 28px;
        padding-left: 14px;
        padding-right: 14px;
        padding-bottom: 14px;
        margin-top: 15px;

        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .preview-image {
            width: 100%;
            height: auto;
            background-color: $preview_background;
        }
    }

    .title {
        display: block;
        margin-bottom: 10px !important;
        font-weight: 800;
        font-size: 22px;
        line-height: 26px;
    }

    .buttons-row {
        margin-top: 30px;
        display: flex;
        justify-content: center;
    }

    .confirm-button {
        display: block;
        padding: 10px 15px;
        background-color: $red;
        border-radius: 7px;
        margin-right: 5px;

        &:hover {
            cursor: pointer;
            background-color: $purple;
            transition: 0.2s;
        }
    }

    .cancel-button {
        margin-left: 5px;
        display: block;
        padding: 10px 15px;
        background-color: $button_gray;
        border-radius: 7px;

        &:hover {
            cursor: pointer;
            background-color: $black;
            transition: 0.2s;
        }
    }
}
