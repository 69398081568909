section.section-account-files {
    .divider {
        width: 100%;
        height: 3px;
        background-color: $gray_light;
    }

    .title {
        font-weight: 800;
        font-size: 22px;
        line-height: 26px;

        margin-bottom: 40px;
    }

    .no-result {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
    }

    .no-result-link {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;

        color: $orange;
        cursor: pointer;

        &:hover {
            cursor: pointer;
            opacity: 0.8;
        }
    }

    .row {
        margin: 0px;
    }

    .capitalize {
        text-transform: capitalize;
    }

    .file-row {
        border-top: 3px;
        border-left: 3px;
        border-right: 3px;
        border-color: $gray_light;
        border-style: solid;
        text-align: center;

        .file-date {
            border-right: 3px;
            border-color: $gray_light;
            border-style: solid;

            display: none;
            @include md {
                display: initial;
            }
        }
        .file-name {
            border-right: 3px;
            border-color: $gray_light;
            border-style: solid;

            padding-left: 20px;
            text-align: left;
        }
        .file-action {
            border-left: 3px;
            border-color: $gray_light;
            border-style: solid;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .file-preview {
            border-right: 3px;
            border-color: $gray_light;
            border-style: solid;
        }
        .file-qr {
            border-right: 3px;
            border-color: $gray_light;
            border-style: solid;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .file-size {
            border-right: 3px;
            border-color: $gray_light;
            border-style: solid;
        }
        .file-status {
        }
        .file-preview {
            border-right: 3px;
            border-color: $gray_light;
            border-style: solid;

            display: flex;
            align-items: center;
            justify-content: center;
        }
        .file-preview-text {
            border-right: 3px;
            border-color: $gray_light;
            border-style: solid;
            padding-top: 16px;
            padding-bottom: 16px;
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
        }
        .file-download {
            text-transform: capitalize;
            display: flex;
            align-items: center;
            justify-content: center;

            a {
                color: inherit;
                text-decoration: inherit;
                display: block;
                width: 100%;
                font-size: 14px;
                font-weight: bold;
            }
        }
        .file-size-format {
            color: $cust_input_text;
        }

        .first {
            padding-top: 16px;
            padding-bottom: 16px;
            font-weight: 800;
            font-size: 14px;
            line-height: 20px;

            @include md {
                font-size: 17px;
            }
        }

        .rest {
            padding-top: 16px;
            padding-bottom: 16px;
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
        }

        .preview-button {
            display: inline-block;
            background-color: $green;
            border-radius: 7px;
            padding: 8px;
            cursor: pointer;
            color: $white;
            font-size: 14px;
            font-weight: bold;

            @include md {
                margin-left: 6px;
                margin-right: 6px;
                padding-left: 20px;
                padding-right: 20px;
            }

            &:hover {
                background-color: $red;
            }
        }

        .download-button {
            background-color: $purple;
            border-radius: 7px;
            padding: 8px;
            cursor: pointer;

            @include lg {
                margin-left: 6px;
                margin-right: 6px;
                padding-left: 20px;
                padding-right: 20px;
            }

            &:hover {
                background-color: $red;
            }
        }

        .delete-icon {
            height: auto;
            width: 24px;
            filter: brightness(0) invert(1);
            transition: 0.2s;

            &:hover {
                cursor: pointer;
                transition: 0.2s;
                filter: invert(30%) sepia(74%) saturate(2012%) hue-rotate(333deg) brightness(79%) contrast(104%);
            }
        }

        .qr-icon {
            height: auto;
            width: 24px;
            filter: brightness(0) invert(1);
            transition: 0.2s;

            &:hover {
                cursor: pointer;
                transition: 0.2s;
                filter: invert(30%) sepia(74%) saturate(2012%) hue-rotate(333deg) brightness(79%) contrast(104%);
            }
        }
    }

    .warning {
        margin-top: 28px;

        color: #fff;
        text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        font-size: 14px;
        font-family: Lato;
        font-style: italic;
        font-weight: 400;
        line-height: normal;
    }

    .page-buttons {
        margin-top: 80px;
    }

    .info-icon {
        height: auto;
        width: 15px;
        filter: invert(54%) sepia(59%) saturate(1255%) hue-rotate(345deg) brightness(92%) contrast(98%);

        transition: 0.2s;
        margin-left: 7px;
        position: relative;
        top: -1px;

        &:hover {
            cursor: pointer;
            transition: 0.2s;
            filter: invert(30%) sepia(74%) saturate(2012%) hue-rotate(333deg) brightness(79%) contrast(104%);
        }
    }

    .error-icon {
        height: auto;
        width: 15px;
        margin-left: 8px;
        position: relative;
        top: -1px;
        filter: invert(60%);

        &:hover {
            cursor: pointer;
            filter: invert(100%);
        }
    }

    .limits {
        .renders-left {
            margin-left: 7px;
            font-weight: bold;
        }

        .sub-needed {
            font-size: 14px !important;
            font-weight: normal !important;
            font-style: italic;
        }

        .zero {
            font-weight: bold;
        }
    }
}
