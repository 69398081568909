section.section-catalog {
    min-height: 100vh;
    background-color: $gray;
    padding-bottom: 70px;

    padding-top: 100px;
    @include lg {
        padding-top: 138px;
    }
    &.customview {
        padding-top: 20px;
    }

    .container {
        max-width: initial;
    }

    .entire-container {
        justify-content: center;
    }

    .dark-background {
        position: fixed;
        left: 0px;
        top: 0px;
        right: 0px;
        bottom: 0px;
        pointer-events: none;

        background: rgba(0, 0, 0, 0);
        transition: 0.3s;
        z-index: 998;

        &.open {
            background: rgba(0, 0, 0, 0.8);
            transition: 0.3s;
            pointer-events: all;
        }
    }

    .title-name-group {
        margin-bottom: 38px;
    }

    .mobile-filter-menu {
        display: none;
        background-color: $mobile_menu;
        z-index: 999;

        pointer-events: all;
        overflow-y: scroll;
        overflow-x: hidden;
        overscroll-behavior: contain;

        position: fixed;
        top: 12.5%;
        left: 7.5%;
        height: 75%;
        width: 85%;

        color: $white;

        &.open {
            display: block;
        }

        .divider {
            width: 100%;
            height: 3px;
            background-color: $gray_light;
        }

        .title {
            margin-top: 38px;
            margin-bottom: 38px;
            margin-left: 50px;

            font-weight: 800;
            font-size: 22px;
            line-height: 26px;
        }

        .icon-close {
            width: 14px;
            height: 14px;
            position: absolute;
            top: 45px;
            right: 47px;

            &:hover {
                cursor: pointer;
            }
        }

        .button-close {
            margin-left: 50px;
            margin-right: 50px;
            margin-bottom: 50px;

            background-color: $purple;
            border-radius: 9px;

            padding-top: 15px;
            padding-bottom: 15px;
            text-align: center;
            font-weight: 800;
            font-size: 16px;
            line-height: 19px;
            transition: 0.2s;

            &:hover {
                cursor: pointer;
                background-color: $red;
                transition: 0.2s;
            }
        }

        .mobile-filter-block {
            padding-bottom: 50px;
            padding-left: 50px;

            .item {
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                margin-bottom: 14px;
                display: flex;
                align-items: center;
            }

            .filter-icon-container {
                border-radius: 7px;
                height: 32px;
                width: 32px;
                margin-right: 20px;

                .filter-icon {
                    height: 25px;
                    width: 25px;
                    margin-right: 22px;

                    cursor: pointer;
                    position: relative;
                    left: 3px;
                    top: 2px;

                    &.-disabled {
                        opacity: 0.4;
                    }

                    &:hover {
                        opacity: 0.85;
                        &.-disabled {
                            cursor: default;
                            opacity: 0.4;
                        }
                    }
                }
            }

            .selection-text {
                font-style: italic;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: $white;
                opacity: 0.65;
                cursor: pointer;

                &.first {
                    margin-right: 30px;
                }
            }

            .mobile-filter-block-left {
                margin-top: 44px;

                .title-name-group {
                    font-weight: 800;
                    font-size: 22px;
                    line-height: 26px;

                    margin-bottom: 38px;
                }
            }
            .mobile-filter-block-right {
                .title-name-group {
                    font-weight: 800;
                    font-size: 22px;
                    line-height: 26px;

                    margin-top: 50px;
                    margin-bottom: 38px;
                }

                .title-name-format {
                    font-weight: 800;
                    font-size: 22px;
                    line-height: 26px;

                    margin-bottom: 38px;
                }
            }
        }
    }

    .divider-top {
        width: 100%;
        height: 3px;
        background-color: $gray_light;
    }

    .top-container {
        font-weight: 800;
        font-size: 25px;
        line-height: 30px;
        text-align: center;
        color: $white;

        height: 100px;
        padding-top: 30px;
    }

    .left-filter-container {
        display: none;

        @include lg {
            display: block;
        }

        font-weight: 800;
        font-size: 22px;
        line-height: 26px;
        color: $white;

        padding-top: 39px;
        padding-left: 54px;

        border-right: 3px;
        border-color: $gray_light;
        border-style: solid;

        .categories-block {
            margin-top: 38px;
        }

        .formats-block {
            margin-top: 38px;
            margin-bottom: 66px;
        }

        .groups-block {
            margin-top: 38px;
            margin-bottom: 66px;
        }

        .apps-block {
            margin-top: 38px;
            margin-bottom: 66px;
        }

        .item {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 22px;
            display: flex;
            align-items: center;
        }

        .filter-icon-container {
            border-radius: 7px;
            height: 25px;
            width: 25px;
            margin-right: 10px;

            .filter-icon {
                height: 20px;
                width: 20px;
                margin-right: 22px;
                user-select: none;
                position: relative;
                left: 2px;
            }

            &:hover {
                cursor: pointer;
                opacity: 0.85;

                &.-disabled {
                    cursor: default;
                }
            }
        }

        .selection-text {
            font-style: italic;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: $white;
            opacity: 0.65;
            cursor: pointer;

            &.first {
                margin-right: 30px;
            }

            &:hover {
                opacity: 1;
            }
        }
    }

    .items-container {
        font-weight: 800;
        font-size: 22px;
        line-height: 26px;
        color: $white;

        padding-top: 39px;

        padding-left: 30px;
        padding-right: 30px;
        @include lg {
            padding-left: 0px;
            padding-right: 54px;
        }

        .catalog-title {
            width: 100%;
            margin-bottom: 38px;

            margin-left: 30px;
            @include lg {
                margin-left: 54px;
            }
        }

        .catalog-search {
            @include lg {
                margin-left: 54px;
            }

            width: 100%;
            @include lg {
                max-width: 920px;
            }

            // height: 59px;
            border-radius: 7px;
            background-color: $gray_light;

            margin-bottom: 34px;
            @include lg {
                margin-bottom: 40px;
            }

            padding-left: 19px;
            padding-top: 14px;
            padding-bottom: 14px;

            position: relative;

            .search-icon {
                height: 19px;
                width: 19px;
                position: relative;
                top: -2px;
            }

            .search-close-block {
                cursor: pointer;
                position: absolute;
                top: 8px;
                right: 10px;

                width: 40px;
                height: 40px;
                padding-left: 14px;
                padding-top: 4px;

                .search-close-icon {
                    height: 12px;
                    width: 12px;
                    //opacity: 0.3;
                }
            }

            .search-field {
                font-weight: 400;
                font-size: 19px;
                line-height: 23px;

                margin-left: 18px;

                input {
                    background-color: $gray_light;
                    color: $white;
                    box-shadow: none;
                    border: 0px;

                    width: 80%;
                }
                input:focus,
                textarea:focus,
                select:focus {
                    outline: none;
                }
            }
        }

        .catalog-filter-button {
            display: block;
            @include lg {
                display: none;
            }

            width: auto;
            @include md {
                width: 207px;
            }

            background-color: $red;
            border-radius: 8px;
            margin-bottom: 34px;

            padding-top: 14px;
            padding-bottom: 14px;

            font-weight: 800;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: $white;
            cursor: pointer;
        }

        .catalog-results-text {
            margin-left: 30px;
            @include lg {
                margin-left: 54px;
            }

            width: 100%;
            font-style: italic;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
        }

        .catalog-results {
            display: flex;
            flex-wrap: wrap;

            justify-content: space-between;
            @include lg {
                justify-content: flex-start;
            }
            max-width: 1300px;

            &.customview {
                justify-content: space-between;
                max-width: none;
            }
        }

        .catalog-result-item {
            margin-top: 27px;
            margin-bottom: 27px;

            @include lg {
                margin-left: 54px;
            }
        }
    }

    .button-bar {
        margin-top: 80px;
    }
}
