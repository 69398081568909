.pages-button-container {
    justify-content: center;
    text-align: center;
    color: $page_button;

    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.button {
    width: fit-content;
    margin-left: 6px;
    margin-right: 6px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 7px;
    padding-bottom: 7px;
    border-radius: 4px;
    background-color: $mobile_menu;

    cursor: pointer;

    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    &:hover {
        color: $orange;
    }

    &.number {
        color: $white;

        &:hover {
            color: $orange;
        }
    }

    &.selected {
        background-color: $red;
        color: $white;
        cursor: default;

        &:hover {
            color: $white;
        }
    }

    &.etc {
        color: $white;
        cursor: default;

        &:hover {
            color: $white;
        }
    }
}
