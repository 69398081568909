.dark-background {
    position: fixed;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    pointer-events: none;

    background: rgba(0, 0, 0, 0);
    transition: 0.3s;
    z-index: 998;

    &.open {
        background: rgba(0, 0, 0, 0.8);
        transition: 0.3s;
        pointer-events: all;
    }
}

.modal-logout {
    display: none;
    background-color: $mobile_menu;
    z-index: 999;
    pointer-events: all;

    overflow-y: auto;
    overflow-x: hidden;
    overscroll-behavior: contain;

    position: fixed;

    top: 20%;
    left: 10%;
    width: 80%;
    @include md {
        top: 20%;
        left: 20%;
        width: 60%;
    }
    @include lg {
        top: 25%;
        left: 25%;
        width: 50%;
    }
    @include xl {
        top: 30%;
        left: 33%;
        width: 33%;
    }

    color: $white;

    &.open {
        display: block;
    }

    .icon-close {
        width: 14px;
        height: 14px;
        position: absolute;
        top: 28px;
        right: 28px;

        &:hover {
            cursor: pointer;
        }
    }

    .logout-block {
        padding-top: 40px;
        padding-bottom: 40px;
        padding-left: 90px;
        padding-right: 90px;

        text-align: center;

        .title {
            font-weight: 800;
            font-size: 25px;
            line-height: 30px;

            margin-bottom: 40px;
        }

        .text {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;

            margin-bottom: 40px;
        }

        .button-logout {
            background-color: $red;
            border-radius: 7px;

            font-weight: 800;
            font-size: 16px;
            line-height: 19px;
            cursor: pointer;

            padding-top: 15px;
            height: 51px;
            margin-bottom: 40px;

            &:hover {
                background-color: $purple;
            }
        }

        .loading-icon {
            height: auto;
            width: 24px;
            margin-top: -2px;

            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

        .text-underlined {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            text-decoration: underline;
            cursor: pointer;

            &:hover {
                color: $orange;
            }
        }
    }
}
