//colours
$white: #fff;
$black: #000;

$purple: #58459f;
$green: #7c8f36;
$orange: #e9771e;
$red: #cd2b38;

$gray: #262626;
$gray_dark: #0f0f0f;
$gray_light: #3a3a3a;

$error_border: #f56868;
$error_text: #ffa3a3;
$message_text: #c7d889;

$search_gray: #a6a6a6;
$button_gray: #2e2e2e;
$page_button: #939393;
$menu_gray1: #a1a1a1;
$menu_gray2: #cecece;
$menu_gray3: #a2a2a2;
$mobile_menu: #1f1f1f;
$cust_input_text: #aaaaaa;
$preview_background: #171717;
$price_item_divider: #616161;
$button_disabled: grey;
