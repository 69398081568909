section.section-account {
    min-height: 100vh;
    background-color: $gray;
    color: $white;

    padding-top: 100px;
    @include lg {
        padding-top: 138px;
    }

    .divider {
        width: 100%;
        height: 3px;
        background-color: $gray_light;
    }

    .text-title {
        margin-top: 34px;
        margin-bottom: 34px;

        font-weight: 800;
        font-size: 25px;
        line-height: 30px;

        text-align: center;
    }

    .mobile-account-menu {
        display: block;
        @include xl {
            display: none;
        }

        a {
            color: $white;
            text-decoration: none !important;
        }

        .menu-item {
            position: relative;
            margin-bottom: 24px;
            width: fit-content;

            font-weight: 400;
            font-size: 12px;
            @include md {
                font-size: 19px;
                line-height: 23px;
            }

            .underlined {
                height: 2px;
                width: 100%;
                position: absolute;
                bottom: -5px;
                left: 0px;
            }
        }
    }

    .left-container {
        display: none;
        padding-top: 44px;
        padding-left: 57px;

        @include xl {
            display: block;
        }

        a {
            text-decoration: none !important;
        }

        .menu-item {
            position: relative;
            margin-bottom: 24px;
            margin-left: 20px;
            width: fit-content;

            font-weight: 400;
            font-size: 19px;
            line-height: 23px;

            color: $white;
            cursor: pointer;

            &:hover {
                color: $orange;
            }

            .underlined {
                height: 2px;
                width: 100%;
                position: absolute;
                bottom: -10px;
                left: 0px;
            }
        }
    }

    .right-container {
        padding-top: 40px;
        padding-bottom: 40px;

        @include md {
            padding-left: 54px;
            padding-right: 54px;
        }

        @include lg {
            border-left: 3px;
            border-color: $gray_light;
            border-style: solid;
        }
    }
}
