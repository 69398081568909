section.section-processing {
    min-height: 100vh;
    background-color: $gray;
    color: $white;
    padding-bottom: 70px;

    padding-top: 100px;
    @include lg {
        padding-top: 138px;
    }

    text-align: center;
    @include lg {
        text-align: left;
    }

    padding-left: 36px;
    padding-right: 36px;
    @include md {
        padding-left: 0px;
        padding-right: 0px;
    }

    .divider {
        width: 100%;
        height: 3px;
        background-color: $gray_light;
    }

    .text-header {
        margin-top: 44px;
        margin-bottom: 44px;

        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        text-align: left;
        color: $menu_gray1;

        a {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;

            :hover {
                color: $white;
            }
        }

        .nonselectable {
            cursor: default;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

        .arrow {
            margin-left: 10px;
            margin-right: 10px;
        }
    }

    .processing-title {
        font-weight: 800;
        font-size: 36px;
        line-height: 43px;
        text-align: center;

        margin-top: 60px;
    }

    .processing-text {
        font-weight: 800;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        margin-bottom: 10px;
    }

    .processing-message {
        font-weight: 800;
        font-size: 20px;
        line-height: 24px;
        text-align: center;

        margin-top: 80px;
        margin-bottom: 80px;
    }

    .progress-icon {
        height: 60px;
        width: auto;
        margin-top: 80px;

        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .sync-icon {
        height: 60px;
        width: auto;
        margin-top: 80px;

        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .error-icon {
        height: 50px;
        width: auto;
        margin-top: 80px;

        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .link {
        color: $orange;
        cursor: pointer;
        font-weight: 800;
        font-size: 20px;
        line-height: 24px;
        transition: 0.2s;

        &:hover {
            color: $white;
            transition: 0.2s;
        }
    }

    .preview-image {
        width: 384px;
        height: auto;
        background-color: $preview_background;

        margin-top: 60px;
        margin-bottom: 44px;
    }

    .download-text {
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
    }

    .download-button {
        margin-top: 30px;
        margin-bottom: 60px;

        font-weight: 800;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        cursor: pointer;

        background-color: $purple;
        border-radius: 7px;
        padding-top: 17px;
        padding-bottom: 17px;
        width: 384px;

        &:hover {
            background-color: $red;
        }
    }

    .qr-code {
        background-color: $white;
        padding: 16px;

        width: 258px;
        height: auto;

        margin-top: 30px;
        margin-bottom: 60px;
    }

    .rendering-error-title {
        text-align: center;
        font-weight: 800;
        color: $menu_gray1;
        line-height: normal;
        font-size: 20px;
    }
    .rendering-error-text {
        text-align: center;
        font-weight: 400;
        color: $menu_gray1;
        line-height: normal;
    }
}
