html {
    overflow-y: scroll;
}

html,
body {
    font-size: 16px;
    line-height: 20px;
    font-family: $font !important;
    font-style: normal;
    color: $black;

    position: relative;
    margin: 0;
    padding: 0;

    min-height: 100vh;

    a {
        color: $orange;

        &:hover {
            color: $orange;
        }
    }
}

.test1 {
    border-color: aqua;
    border: 1px;
    border-style: solid;
}

.test2 {
    background-color: aqua;
}

.hide-on-mobile {
    display: none;

    @include md {
        display: initial;
    }
}

.cookie-consent-bar {
    //important needed to overwrite styling of package
    background: $black !important;
    align-items: center !important;
    right: 0 !important;
    left: 0 !important;
    bottom: 0 !important;
    height: fit-content !important;
    flex-direction: column !important;
    flex-wrap: nowrap !important;

    @include md {
        flex-direction: row !important;
    }

    #rcc-confirm-button {
        //important needed to overwrite styling of package
        background: $red !important;
        transition: 0.2s;
        padding: 15px 40px !important;
        color: $white !important;
        margin: 0px !important;
        width: 100%;
        border-radius: 7px !important;

        &:hover {
            background: $purple !important;
            transition: 0.2s;
        }
    }

    #rcc-decline-button {
        //important needed to overwrite styling of package
        background: $white !important;
        transition: 0.2s;
        padding: 15px 40px !important;
        color: $black !important;
        margin: 10px 0 0 0 !important;
        width: 100%;
        border-radius: 7px !important;

        &:hover {
            transition: 0.2s;
            opacity: 0.8;
        }
    }

    .cookie-bar {
        padding: 10px;
        width: 100%;

        @include lg {
            padding: 30px;
            width: 80%;
        }

        .cookie-title {
            font-size: 22px;
            display: block;
            font-weight: 600;
            color: $font;
            font-family: 'Lato';
            margin-bottom: 10px;
        }

        .cookie-text {
            color: $font;
            font-size: 16px;
            line-height: 28px;
        }
    }

    .buttons {
        position: relative;
        padding: 15px;
        flex: none !important;
        margin-right: 15px;
        width: 90%;

        @include md {
            width: 210px;
        }
    }

    .content-wrapper {
        width: 90%;
        flex: none !important;

        @include md {
            width: 60%;
        }
    }
}

.react-tooltip {
    max-width: 100% !important;
}
