header {
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 997;
    background-color: $black;

    height: 100px;
    @include lg {
        height: 138px;
    }

    &.custom-view {
        position: relative;
        height: 250px;
    }

    .mobile-menu-icon {
        position: absolute;
        height: 17px;
        width: 23px;
        top: 41px;
        left: 34px;
    }

    .header-logo {
        text-align: center;

        @include lg {
            text-align: initial;
        }

        .logo {
            margin-top: 27px;
            width: 144;
            height: 44px;

            @include lg {
                position: relative;
                top: 28px;
                left: 46px;

                margin-top: 0px;
                width: 213px;
                height: 68px;
            }

            &:hover {
                cursor: pointer !important;
            }
        }
    }

    .header-menu {
        text-align: right;
        padding-right: 49px;
        padding-top: 57px;

        .menu-item {
            font-weight: 700;
            font-size: 17px;
            line-height: 20px;

            color: $white;
            cursor: pointer;
            position: relative;
            margin-left: 60px;

            &:hover {
                color: $orange;
            }
            .underlined {
                height: 2px;
                width: 100%;
                position: absolute;
                bottom: -13px;
                left: 0px;
            }
        }

        .language-setting {
            margin-left: 60px;

            select,
            option {
                -webkit-appearance: none;
                background-color: $black;
                color: $menu_gray1;
                border: 0px;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    .menu-dark-background {
        position: fixed;
        left: 0px;
        top: 0px;
        right: 0px;
        bottom: 0px;

        background: rgba(0, 0, 0, 0);
        transition: 0.3s;
        pointer-events: none;
        z-index: 98;

        &.open {
            background: rgba(0, 0, 0, 0.5);
            transition: 0.3s;
            pointer-events: inherit;
        }
    }

    .mobile-menu {
        left: -80vw;
        background-color: $mobile_menu;
        position: fixed;
        height: 100%;
        top: 0px;
        bottom: 0px;
        transition: 0.3s;
        width: 70vw;
        z-index: 99;

        &.open {
            transition: 0.3s;
            left: 0px;
        }

        .menu-top {
            padding-left: 47px;
            padding-top: 40px;
            height: 100px;

            font-weight: 600;
            font-size: 17px;
            line-height: 20px;
            color: $white;

            .menu-icon-close {
                width: 14px;
                height: 14px;
                position: absolute;
                top: 41px;
                right: 33px;

                &:hover {
                    cursor: pointer;
                }
            }
        }

        .language-setting-mobile {
            position: absolute;
            top: 136px;
            right: 33px;

            select,
            option {
                -webkit-appearance: none;
                background-color: $mobile_menu;
                color: $menu_gray1;
                border: 0px;
            }
        }

        .menu-divider {
            width: 100%;
            height: 1px;
            background-color: $gray_light;
        }

        .menu-container {
            padding-top: 35px;
            padding-left: 46px;
        }

        .mobile-menu-item-container {
            margin-bottom: 34px;
        }

        .mobile-menu-item {
            font-weight: 700;
            font-size: 17px;
            line-height: 20px;

            color: $white;
            cursor: pointer;
            position: relative;

            &:hover {
                color: $orange;
            }

            .underlined {
                height: 2px;
                width: 100%;

                position: absolute;
                bottom: -13px;
                left: 0px;
            }
        }
    }

    .custom-logo-container {
        padding: 25px 0;
        height: 250px;

        .custom-logo {
            height: auto;
            width: auto;
            max-width: 100%;
            max-height: 250px;
        }
    }
}
