section.section-account-details {
    input {
        //background-color: $gray_light;
        background-color: transparent !important;
        color: $white !important;
        box-shadow: none;
        border: 0px;
        width: 100%;
        margin-top: 12px;
        padding-left: 10px;
    }
    input:focus,
    textarea:focus,
    select:focus {
        outline: none;
    }
    input:-webkit-autofill {
        -webkit-text-fill-color: $white !important;
        background-color: transparent !important;
        -webkit-box-shadow: 0 0 0 50px $gray_light inset;
    }

    .divider {
        width: 100%;
        height: 3px;
        background-color: $gray_light;

        margin-top: 25px;
        margin-bottom: 60px;
    }

    .title {
        font-weight: 800;
        font-size: 22px;
        line-height: 26px;

        margin-bottom: 37px;
    }

    .message-details {
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;

        color: $message_text;
        &.error {
            color: $error_text;
        }

        margin-bottom: 21px;
    }

    .details-container {
        margin-top: 12px;
    }

    .input-text {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 25px;
    }
    .input-field {
        background-color: $gray_light;
        border-radius: 7px;
        height: 51px;
        margin-bottom: 35px;
        padding-left: 20px;
        padding-right: 20px;
        position: relative;

        &.error {
            border-color: $error_border;
            border-width: 1px;
            border-style: solid;
        }

        .icon-password {
            width: 21px;
            height: auto;
            position: absolute;
            right: 20px;
            cursor: pointer;

            top: 17px;
            &.visible {
                top: 19px;
            }
        }
    }
    .save-button {
        background-color: $purple;
        border-radius: 7px;

        font-weight: 800;
        font-size: 16px;
        line-height: 19px;
        cursor: pointer;
        text-align: center;

        padding-top: 15px;
        height: 51px;
        margin-bottom: 80px;

        &:hover {
            background-color: $red;
        }
    }
    .loading-icon {
        height: auto;
        width: 24px;
        margin-top: -2px;

        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
}
