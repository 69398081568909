section.section-customize {
    min-height: 100vh;
    background-color: $gray;
    color: $white;
    padding-bottom: 70px;

    padding-top: 100px;
    @include lg {
        padding-top: 138px;
    }

    text-align: center;
    @include lg {
        text-align: left;
    }

    padding-left: 36px;
    padding-right: 36px;
    @include md {
        padding-left: 0px;
        padding-right: 0px;
    }

    .divider {
        width: 100%;
        height: 3px;
        background-color: $gray_light;
    }

    .text-header {
        margin-top: 44px;
        margin-bottom: 44px;

        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        text-align: left;
        color: $menu_gray1;

        .linkback {
            cursor: pointer;
        }

        .nonselectable {
            cursor: default;
        }

        .arrow {
            margin-left: 10px;
            margin-right: 10px;
        }

        a {
            color: $orange;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;

            :hover {
                color: $white;
            }
        }
    }

    .preview-container {
        margin-top: 44px;
        @include lg {
            margin-top: 100px;
        }

        .preview-title {
            font-style: italic;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;

            margin-bottom: 20px;
        }

        .item-preview {
            background-color: $preview_background;
            position: relative;

            width: 100%;
            @include md {
                width: 80%;
            }
            @include lg {
                width: 100%;
            }

            .video-properties {
                height: 100%;
                width: 100%;
                object-fit: contain;
                margin-bottom: -10px;
            }
            .image {
                height: 100%;
                width: 100%;
                user-select: none;
            }

            .watermark {
                position: absolute;
                height: 100%;
                width: 100%;
                left: 0px;
                right: 0px;
                top: 0px;
                bottom: 0px;
                user-select: none;
                object-fit: cover;
            }
        }
    }

    .customize-container {
        @include lg {
            padding-left: 40px;
        }

        .item-title {
            font-weight: 800;
            font-size: 36px;
            line-height: 43px;

            margin-top: 60px;
            margin-bottom: 36px;
        }

        .tags {
            justify-content: center;
            @include lg {
                justify-content: left;
            }
        }

        .item-tag {
            background-color: $button_gray;
            border-radius: 5px;
            height: 36px;
            padding-left: 15px;
            padding-right: 15px;
            padding-top: 1px;

            width: fit-content;
            margin-right: 10px;
            margin-bottom: 10px;

            text-align: center;
            font-weight: 700;
            font-size: 12px;
            line-height: 33px;
        }

        .description {
            margin-top: 40px;
            margin-bottom: 22px;

            font-weight: 400;
            font-size: 20px;
            line-height: 33px;
        }

        .customize-options {
            .option-title {
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
                text-align: left;

                margin-bottom: 20px;
            }

            .option-text {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
            }

            .option-string {
                margin-bottom: 32px;

                .option-limit-text {
                    font-size: 10px;
                    line-height: 24px;
                    position: absolute;
                    bottom: 0px;
                    right: 8px;
                }

                .input-field {
                    background-color: $gray_light;
                    border-radius: 7px;
                    padding-left: 25px;
                    padding-top: 13px;
                    padding-bottom: 13px;
                    padding-right: 25px;

                    color: $cust_input_text;
                    font-weight: 400;
                    font-size: 17px;
                    line-height: 20px;
                    text-align: left;
                    position: relative;

                    input {
                        background-color: $gray_light;
                        color: $white;
                        box-shadow: none;
                        border: 0px;
                        width: 100%;
                    }
                    input:focus,
                    textarea:focus,
                    select:focus {
                        outline: none;
                    }
                }
            }

            .option-size {
                margin-bottom: 32px;

                .option-size-container {
                    position: relative;
                    text-align: left;

                    .option-size-icon {
                        height: 32px;
                        width: 32px;
                        position: absolute;
                        top: 9px;
                        left: 15px;
                        pointer-events: none;
                        cursor: pointer;
                    }
                }

                select,
                option {
                    -webkit-appearance: none;
                    background-color: $gray_light;
                    color: $white;
                    border: 0px;
                    padding-top: 12px;
                    padding-bottom: 12px;
                    padding-left: 55px;
                    padding-right: 25px;
                    width: 200px;
                    cursor: pointer;
                }

                select:focus {
                    outline: none;
                }
            }

            .option-toggle {
                margin-bottom: 32px;
                text-align: left;

                .option-toggle-icon {
                    height: 32px;
                    width: 32px;
                    cursor: pointer;

                    position: relative;
                    top: 0px;
                    left: 0px;
                    margin-right: 21px;
                }
            }

            .option-color {
                margin-bottom: 32px;
                position: relative;

                .option-title {
                    cursor: pointer;
                }

                .option-color-pointer {
                    cursor: pointer;
                }
            }

            .option-color-picker {
                // padding-left: 25px;
                position: relative;
            }

            .option-date {
                margin-bottom: 32px;

                .option-title {
                    cursor: pointer;
                }

                .option-selected-date {
                    display: block;
                    margin-top: 20px;
                    background-color: #3a3a3a;
                    border-radius: 7px;
                    padding-left: 25px;
                    padding-top: 14px;
                    padding-bottom: 13px;
                    padding-right: 25px;
                    color: #aaaaaa;
                    font-weight: 400;
                    font-size: 17px;
                    line-height: 20px;
                    text-align: left;
                    position: relative;
                    min-height: 48px;

                    &:hover {
                        cursor: pointer;
                    }

                    .datepicker-icon {
                        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(222deg) brightness(102%) contrast(103%);
                        position: absolute;
                        right: 10px;
                        top: 11px;
                        opacity: 0.8;
                    }
                }

                .rdp {
                    padding: 10px;
                    border-radius: 7px;
                    width: fit-content;
                    background-color: $gray_light;
                    --rdp-accent-color: #7c8f36;
                    --rdp-background-color: #c7d889;
                }
            }

            .option-number {
                margin-bottom: 32px;

                .input-field {
                    width: fit-content;
                    background-color: $gray_light;
                    border-radius: 7px;
                    padding-left: 25px;
                    padding-right: 25px;
                    padding-top: 13px;
                    padding-bottom: 13px;

                    color: $cust_input_text;
                    font-weight: 400;
                    font-size: 17px;
                    line-height: 20px;

                    input {
                        width: 150px;
                        background-color: $gray_light;
                        color: $white;
                        box-shadow: none;
                        border: 0px;
                    }
                    input:focus,
                    textarea:focus,
                    select:focus {
                        outline: none;
                    }
                }
            }

            .option-image {
                margin-bottom: 32px;

                .error-text {
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 16px;
                    color: $error_text;
                    margin-top: 8px;
                    margin-left: 8px;
                }

                .loading-icon {
                    height: auto;
                    width: 24px;
                    margin-top: -2px;

                    -webkit-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                }

                .info-icon {
                    height: auto;
                    width: 15px;
                    position: absolute;
                    top: 16px;
                    right: 14px;

                    filter: invert(60%);

                    &:hover {
                        cursor: pointer;
                        filter: invert(100%);
                    }
                }

                .icon-close {
                    width: 10px;
                    height: 10px;
                    position: absolute;
                    top: 20px;
                    right: 20px;

                    &:hover {
                        cursor: pointer;
                    }
                }

                .upload-preview {
                    width: 24px;
                    height: 24px;
                    border-radius: 7px;
                    background-color: $gray;
                }

                .upload-filename {
                    margin-left: 12px;
                    padding-top: 2px;
                    color: $white;
                }

                .tooltip-title {
                    font-weight: 700;
                    font-size: 12px;
                }
                .tooltip-option {
                    font-size: 12px;
                }
                .tooltip-option-small {
                    margin-left: 14px;
                    font-size: 10px;
                }
                .tooltip-icon {
                    height: 6px;
                    width: 4px;

                    margin-right: 10px;
                    padding-bottom: 2px;
                }

                .input-field {
                    background-color: $gray_light;
                    border-radius: 7px;
                    padding-left: 25px;
                    padding-top: 13px;
                    padding-bottom: 13px;
                    padding-right: 25px;

                    color: $cust_input_text;
                    font-weight: 400;
                    font-size: 17px;
                    line-height: 20px;
                    text-align: left;
                    position: relative;

                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    overflow: hidden;

                    input[type='file'] {
                        cursor: pointer;
                        color: transparent;
                    }

                    input[type='file']::file-selector-button {
                        border: 0px;
                        border-radius: 7px;
                        background-color: $green;
                        color: $white;
                        font-size: 16px;
                        padding-left: 8px;
                        padding-right: 8px;
                        //height: 30px;
                    }

                    input[type='file']::file-selector-button:hover {
                        background-color: $purple;
                        cursor: pointer;
                    }

                    &.error {
                        border-color: $error_border;
                        border-width: 1px;
                        border-style: solid;
                    }
                }
            }
        }

        .get-button {
            margin-top: 48px;

            background-color: $purple;
            border-radius: 7px;
            padding-top: 17px;
            padding-bottom: 17px;
            cursor: pointer;

            text-align: center;
            font-weight: 800;
            font-size: 16px;
            line-height: 19px;

            &.loading {
                background-color: $green;
            }

            &:hover {
                background-color: $red;
            }
        }

        .sub-button {
            margin-top: 48px;

            background-color: $red;
            border-radius: 7px;
            padding-top: 17px;
            padding-bottom: 17px;
            cursor: pointer;

            text-align: center;
            font-weight: 800;
            font-size: 16px;
            line-height: 19px;

            &:hover {
                background-color: $green;
            }
        }

        .feedback-text {
            margin-top: 48px;
            font-size: 12px;
            line-height: 24px;
            opacity: 0.9;
        }
    }

    .limit-reached {
        color: $error_text;
        padding-top: 20px;
        display: block;
    }

    .date-picker-container {
        position: relative;
    }
}
