section.section-pricing {
    min-height: 100vh;
    background-color: $gray;
    color: $white;

    padding-top: 100px;
    @include lg {
        padding-top: 138px;
    }

    .divider {
        width: 100%;
        height: 3px;
        background-color: $gray_light;
    }

    .text-title {
        margin-top: 34px;
        margin-bottom: 34px;

        font-weight: 800;
        font-size: 25px;
        line-height: 30px;

        text-align: center;
    }
    .text-paragraph {
        margin-top: 54px;
        padding-left: 16px;
        padding-right: 16px;

        font-weight: 400;
        font-size: 16px;
        line-height: 33px;
        text-align: center;
    }
    .loading-icon {
        height: auto;
        width: 22px;
        margin-top: -2px;

        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .error-text {
        font-weight: 400;
        font-size: 21px;
        line-height: 16px;
        color: $error_text;

        text-align: center;
        margin-top: 38px;
        //margin-bottom: 30px;
    }

    .items {
        padding-bottom: 157px;

        .item {
            background-color: $gray_light;
            width: 312px;

            position: relative;

            border-radius: 5px;
            margin-left: 21px;
            margin-right: 21px;
            margin-top: 30px;
            padding: 0px;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            .title-item {
                font-weight: 800;
                font-size: 21px;
                line-height: 33px;
                text-align: center;

                padding-top: 20px;
                padding-bottom: 20px;
            }

            .divider-item {
                width: 100%;
                height: 1px;
                background-color: $price_item_divider;
            }

            .text-item {
                padding-top: 40px;
                padding-left: 35px;
                padding-right: 35px;

                .sentence-item {
                    margin-bottom: 30px;
                    display: flex;
                }

                .sentence-item-icon {
                    height: 28px;
                    width: 28px;

                    margin-right: 26px;
                }
            }

            .bottom-container {
            }

            .price-item {
                padding-top: 25px;
                padding-bottom: 55px;

                font-weight: 800;
                font-size: 32px;
                line-height: 33px;
                text-align: center;
            }

            .purchase-button {
                margin-bottom: 25px;
                margin-left: 25px;
                margin-right: 25px;

                cursor: pointer;
                font-weight: 800;
                font-size: 16px;
                line-height: 19px;
                text-align: center;

                border-radius: 7px;
                padding-top: 17px;
                padding-bottom: 17px;

                &.purple {
                    background-color: $purple;
                }

                &.green {
                    background-color: $green;
                }

                &.orange {
                    background-color: $orange;
                }

                &:hover {
                    background-color: $red;
                }
            }

            .owned-button {
                font-weight: 800;
                font-size: 16px;
                line-height: 19px;
                text-align: center;
                height: 51px;

                border-radius: 7px;
                padding-top: 17px;
                padding-bottom: 17px;

                margin-bottom: 25px;
                margin-left: 25px;
                margin-right: 25px;

                background-color: $price_item_divider;
                color: $white;
            }

            .empty-buton {
                height: 51px;
                border-radius: 7px;
                padding-top: 17px;
                padding-bottom: 17px;

                margin-bottom: 25px;
                margin-left: 25px;
                margin-right: 25px;

                border: 1px;
                border-style: solid;
                border-color: $price_item_divider;
            }
        }
    }
}
