.dark-background {
    position: fixed;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    pointer-events: none;

    background: rgba(0, 0, 0, 0);
    transition: 0.3s;
    z-index: 998;

    &.open {
        background: rgba(0, 0, 0, 0.8);
        transition: 0.3s;
        pointer-events: all;
    }
}

.modal-pwreset {
    display: none;
    background-color: $mobile_menu;
    z-index: 999;
    pointer-events: all;

    overflow-y: auto;
    overflow-x: hidden;
    overscroll-behavior: contain;

    position: fixed;

    top: 5%;
    left: 5%;
    width: 90%;
    @include md {
        top: 20%;
        left: 20%;
        width: 60%;
    }
    @include lg {
        top: 10%;
        left: 20%;
        width: 60%;
    }
    @include xl {
        top: 10%;
        left: 30%;
        width: 40%;
    }

    color: $white;

    &.open {
        display: block;
    }

    .icon-close {
        width: 14px;
        height: 14px;
        position: absolute;
        top: 28px;
        right: 28px;

        &:hover {
            cursor: pointer;
        }
    }

    .pwreset-block {
        padding-top: 20px;
        padding-bottom: 20px;

        @include md {
            padding-left: 40px;
            padding-right: 40px;
            padding-top: 40px;
            padding-bottom: 40px;
        }
        @include lg {
            padding-left: 90px;
            padding-right: 90px;
        }

        text-align: center;

        .title {
            font-weight: 800;
            font-size: 25px;
            line-height: 30px;

            margin-bottom: 40px;
        }

        .text {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;

            margin-bottom: 40px;
            text-align: left;

            &.center {
                text-align: center;
            }
        }

        .button-reset {
            background-color: $red;
            border-radius: 7px;

            font-weight: 800;
            font-size: 16px;
            line-height: 19px;
            cursor: pointer;

            padding-top: 15px;
            height: 51px;
            margin-top: 40px;
            margin-bottom: 40px;

            &:hover {
                background-color: $purple;
            }
        }

        .loading-icon {
            height: auto;
            width: 24px;
            margin-top: -2px;

            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

        .text-underlined {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            text-decoration: underline;
            cursor: pointer;

            &:hover {
                color: $orange;
            }
        }

        .error-text {
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: $error_text;
            margin-bottom: 15px;
            text-align: left;
        }

        input {
            //background-color: $gray_light;
            background-color: transparent !important;
            color: $white !important;
            box-shadow: none;
            border: 0px;
            width: 100%;
            margin-top: 12px;
            padding-left: 10px;
        }
        input:focus,
        textarea:focus,
        select:focus {
            outline: none;
        }
        input:-webkit-autofill {
            -webkit-text-fill-color: $white !important;
            background-color: transparent !important;
            -webkit-box-shadow: 0 0 0 50px $gray_light inset;
        }

        .input-email-text {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 25px;
            text-align: left;
        }
        .input-email-field {
            background-color: $gray_light;
            border-radius: 7px;
            height: 51px;
            margin-bottom: 15px;
            padding-left: 20px;
            padding-right: 20px;

            &.error {
                border-color: $error_border;
                border-width: 1px;
                border-style: solid;
            }
        }

        .input-password-text {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            padding-top: 15px;
            margin-bottom: 25px;
            text-align: left;
        }
        .input-password-field {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            padding-top: 2px;
            padding-left: 20px;

            background-color: $gray_light;
            border-radius: 7px;
            height: 51px;
            margin-bottom: 15px;
            padding-right: 45px;
            position: relative;

            &.error {
                border-color: $error_border;
                border-width: 1px;
                border-style: solid;
            }

            .icon-password {
                width: 21px;
                height: auto;
                position: absolute;
                right: 20px;
                cursor: pointer;

                top: 17px;
                &.visible {
                    top: 19px;
                }
            }
        }
    }
}
