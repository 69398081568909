section.section-account-orderdetails {
    .title {
        font-weight: 800;
        font-size: 22px;
        line-height: 26px;
    }

    .field-error {
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: $error_text;
        min-height: 40px;
        padding-top: 11px;
        padding-bottom: 11px;
    }

    .details-row {
        .details-item-name {
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            text-transform: capitalize;
            color: $cust_input_text;
        }

        .details-text {
            padding-top: 16px;
            padding-bottom: 16px;
            line-height: 18px;

            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .manage-buttons {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        justify-content: center;
        @include md {
            justify-content: left;
        }

        .manage-button {
            background-color: $purple;
            border-radius: 7px;

            font-weight: 800;
            font-size: 16px;
            line-height: 19px;
            cursor: pointer;
            text-align: center;

            height: 51px;
            width: 250px;
            padding-top: 15px;

            margin-top: 40px;
            @include md {
                margin-right: 40px;
            }

            &:hover {
                background-color: $red;
            }
        }
    }
}
