section.section-terms {
    min-height: 100vh;
    background-color: $gray;
    color: $white;

    padding-top: 100px;
    @include lg {
        padding-top: 138px;
    }

    .divider {
        width: 100%;
        height: 3px;
        background-color: $gray_light;
    }

    .text-title {
        margin-top: 34px;
        margin-bottom: 34px;

        font-weight: 800;
        font-size: 25px;
        line-height: 30px;

        text-align: center;
    }
    .text-paragraph {
        margin-top: 54px;

        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 54px;

        @include md {
            padding-left: 32px;
            padding-right: 32px;
        }

        @include lg {
            padding-left: 276px;
            padding-right: 276px;
            padding-bottom: 157px;
        }

        font-weight: 400;
        font-size: 16px;
        line-height: 33px;

        text-align: left;
    }
}
