section.section-payment-info {
    .divider {
        width: 100%;
        height: 3px;
        background-color: $gray_light;
    }

    .title {
        font-weight: 800;
        font-size: 22px;
        line-height: 26px;

        margin-bottom: 40px;
    }
}
