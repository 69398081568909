.section-custom-sharing {
    .title {
        font-weight: 800;
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 37px;
    }

    .text-block {
        display: block;
    }

    .subtitle {
        font-weight: bolder;
        display: block;
    }

    .button {
        display: block;
        background-color: $green;
        border-radius: 7px;
        padding: 10px 15px;
        cursor: pointer;
        color: $white;
        font-size: 14px;
        font-weight: bold;
        margin: 0px;

        &:hover {
            background-color: $red;
        }

        &.-upload {
            background: $purple;
            padding: 20px 75px;
            font-size: 16px;
            text-align: center;
            width: 100%;

            @include lg {
                width: fit-content;
            }

            &:hover {
                background-color: $red;
            }
        }

        &.-delete {
            background: $red;
            padding: 10px 25px;
            font-size: 14px;
            text-align: center;
            width: 100%;

            @include lg {
                width: fit-content;
            }

            &:hover {
                background-color: $black;
            }
        }
        &.-disabled {
            background: $gray_light;
            padding: 20px 75px;
            user-select: none;
            text-align: center;
            width: 100%;
            &:hover {
                cursor: inherit;
            }

            @include lg {
                width: fit-content;
            }
        }
    }

    .custom-link {
        display: inline-block;
        font-size: 16px;
        color: $white;
        padding: 10px 15px;
        background: rgb(90 145 233 / 19%);
        border-radius: 4px;
        line-height: 39px;
        text-decoration: none;

        &:hover {
            color: $white;
            background: $black;
        }
    }
    .table-col {
        border-top: 3px;
        border-left: 3px;
        border-right: 3px;
        border-bottom: 3px;
        border-color: $gray_light;
        border-style: solid;
        padding: 10px 20px;
        text-align: left;

        &.-first {
            border-bottom: 0;
        }
    }

    .currentLogo {
        max-width: 100%;
        max-height: 200px;
        width: auto;
    }

    .color-block {
        height: 40px;
        width: 40px;
        border-radius: 4px;
        position: relative;

        &:hover {
            cursor: pointer;
        }
    }
    .option-color-picker {
        // padding-left: 25px;
        position: absolute;
        left: 50px;
    }
}
