.link {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: $menu_gray3;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        color: $orange;
    }
}

.btn-default {
    transition: 0.2s;
    display: inline-block;
    background-color: $red;
    border-radius: 9px;
    cursor: pointer;
    text-decoration: none;

    font-size: 16px;
    text-align: center;
    padding-top: 16px;
    padding-bottom: 22px;
    padding-left: 30px;
    padding-right: 30px;

    @include md {
        font-size: 18px;
        padding-left: 75px;
        padding-right: 75px;
    }

    @include lg {
        font-size: 22px;
        text-align: left;
        padding-top: 18px;
        padding-bottom: 25px;
        padding-left: 75px;
        padding-right: 75px;
    }

    font-weight: 800;
    line-height: 26px;
    color: $white;

    &:hover {
        background-color: $purple;
        transition: 0.2s;
        color: $white;
    }

    &:focus {
        outline: 0;
    }

    .btn-text {
        vertical-align: middle;
        text-align: center;
    }
}

.btn-small {
    display: block;
    transition: 0.2s;
    text-decoration: none;
    background-color: $purple;
    border-radius: 7px;
    height: 38px;
    margin-left: 17px;
    margin-right: 17px;
    margin-top: 17px;
    padding-top: 8px;

    font-weight: 800;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: $white;

    cursor: pointer;

    &:hover {
        background-color: $red;
        color: $white;
        transition: 0.2s;
    }

    &:focus {
        outline: 0;
    }
}
