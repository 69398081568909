footer {
    // position: absolute;
    // bottom: 0px;
    width: 100%;

    section.section_footer {
        background-color: $gray_dark;

        .footer-menu {
            text-align: left;

            padding-top: 76px;
            padding-bottom: 76px;
            @include lg {
                padding-top: 76px;
                padding-bottom: 76px;
            }

            padding-left: 47px;
            @include md {
                padding-left: 0px;
            }

            .left-menu {
                .footer-logo {
                    width: 138px;
                    height: 44px;
                    margin-bottom: 31px;
                    display: block;

                    &:hover {
                        cursor: pointer !important;
                    }

                    img {
                        height: 100%;
                        width: 100%;
                    }
                }

                .menu-item {
                    position: relative;
                    margin-bottom: 24px;
                    margin-left: 20px;
                    width: fit-content;

                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;
                    color: $menu_gray1;
                    cursor: pointer;

                    &:hover {
                        color: $orange;
                    }

                    .underlined {
                        height: 2px;
                        width: 100%;
                        position: absolute;
                        bottom: -10px;
                        left: 0px;
                    }
                }
            }

            .right-menu {
                padding-top: 17px;
                color: $menu_gray2;

                padding-left: 32px;
                @include md {
                    padding-left: 0px;
                }
                @include lg {
                    padding-left: 52px;
                }

                .title {
                    font-weight: 800;
                    font-size: 15px;
                    line-height: 18px;
                    margin-bottom: 27px;
                }

                .menu-item {
                    margin-bottom: 16px;
                }
            }
        }

        .footer-menu-cr {
            height: 56px;
            padding-top: 22px;
            background-color: $black;
            text-align: center;
            width: 100%;

            font-weight: 400;
            font-size: 11px;
            line-height: 13px;
            color: $white;

            cursor: default;
        }
    }
}
