section.section-account-subscription {
    .divider {
        width: 100%;
        height: 3px;
        background-color: $gray_light;
    }

    .title {
        font-weight: 800;
        font-size: 22px;
        line-height: 26px;

        margin-bottom: 40px;
    }

    .no-result {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
    }

    .no-result-link {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        cursor: pointer;
        color: $orange;
    }

    .row {
        margin: 0px;
    }

    .order-row {
        border-top: 3px;
        border-left: 3px;
        border-right: 3px;
        border-color: $gray_light;
        border-style: solid;
        text-align: center;

        .order-item {
            border-right: 3px;
            border-color: $gray_light;
            border-style: solid;
        }
        .order-status {
            text-transform: capitalize;
        }

        .first {
            padding-top: 16px;
            padding-bottom: 16px;
            font-weight: 800;
            font-size: 17px;
            line-height: 20px;
        }

        .rest {
            padding-top: 16px;
            padding-bottom: 16px;
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
        }

        .view-button {
            background-color: $purple;
            border-radius: 7px;
            margin-left: 6px;
            margin-right: 6px;
            margin-top: 8px;
            margin-bottom: 8px;
            padding-top: 8px;
            padding-bottom: 8px;
            padding-left: 20px;
            padding-right: 20px;
            cursor: pointer;
            display: inline-block;
            color: $white;
            font-size: 14px;
            font-weight: bold;

            &:hover {
                background-color: $red;
            }
        }
    }
}
