.date-picker-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 135px;
    right: 0;
    left: 0px;
    bottom: 0px;
    z-index: 9999;
}
